@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

:root {
  --color-secondary: #027a62;
  --color-primary: #0aac8b;
  --color-white: #fff;
  --color-black: #191b1d;
  --background-gradient: -webkit-linear-gradient(
    0deg,
    rgb(10, 172, 139) 0%,
    rgb(10, 172, 139) 100%
  );
  --background-gradient1: -webkit-linear-gradient(
    270deg,
    #089175 35%,
    #0aac8b 69%
  );
}

a {
  text-decoration: none !important;
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: #089175;
  border: 1px solid var(--color-white);
  border-radius: 5px;
  height: 50px;
  width: 50px;
  color: var(--color-white);
  cursor: pointer;
  animation: 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

/* Bottom to top button style */
.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}

.icon-style {
  background-color: #089175;
  border: 1px solid #fff;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #089175;
  border: 1px solid #089175;
}
/******* Bottom to top button style *******/
.nav_bg {
  background: #020001;
  padding-top: 15px;
}

.me_auto {
  margin-left: auto;
  gap: 25px;
}

.nav_bar_a {
  display: flex;
  justify-content: flex-end;
  gap: 0px;
}

.nav_bar_a a {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  text-decoration: none;
  padding: 20px 0px;
}

.dropdown button {
  background: transparent;
  border: none;
}

.dropdown button:hover {
  color: var(--bs-btn-hover-color);
  background-color: transparent !important;
  border: none !important;
}

.brand_dropdown .btn.show,
:not(.btn-check) + .btn:active {
  color: var(--bs-btn-active-color);
  background-color: transparent !important;
  border: none !important;
}

.brand_dropdown .dropdown-item.active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: #089175 !important;
}

.brand_dropdown .dropdown-item:hover {
  color: white !important;
  background-color: transparent !important;
}

.brand_dropdown .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: -60px;
  margin-top: var(--bs-dropdown-spacer);
  width: 200px;
}

.nav_bar_a a::before {
  position: absolute;
  content: "";
  background: #089175;
  width: 0%;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.nav_bar_a a:hover:before {
  color: var(--color-white);
  width: 100%;
}

nav.nav_bar.navbar.navbar-expand-lg.navbar-light {
  padding: 0px;
}

a.nav_dropdown.dropdown-item {
  padding: 10px;
}

a.nav_link.active::before {
  position: absolute;
  content: "";
  background: #089175;
  width: 100%;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.logo_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 45px;
}

.logo_section_contact a.link {
  font-weight: 600;
  text-decoration: none;
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo_section_contact p {
  color: var(--color-white);
  margin: 0px;
}

.searchbar {
  width: 450px;
}

.searchbar input {
  border-radius: 15px 0px;
  outline: none;
}

.searchbar button {
  padding: 0px 10px;
  border-radius: 0px;
  outline: none;
  border: 0px;
  color: var(--color-white);
  font-size: 22px;
  background: linear-gradient(
    0deg,
    rgba(140, 114, 215, 1) 0%,
    rgba(226, 128, 198, 1) 100%
  );
}

.header_input_icon svg {
  color: var(--color-white);
  font-size: 22px;
}

.header_input {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.header_input i::before {
  position: absolute;
  content: "";
  left: -4px;
  top: -20px;
  width: 0%;
  height: 0%;
  transition: 0.5s ease;
  z-index: -1;
}

.header_input i:hover:before {
  width: 100%;
  padding: 40px 16px;
  height: 100%;
  transition: 0.5s ease;
}

.header_input i {
  position: relative;
  z-index: 99999;
}

a.nav_link {
  color: var(--color-white);
}

a#basic-nav-dropdown {
  color: var(--color-white);
}

.section_head h2 {
  background: black;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 45px;
}

.section_head:after {
  content: "";
  background-color: #089175;
  width: 300px;
  height: 2.5px;
  display: block;
  margin-left: -19px;
}

.tabs ul#product-tabs {
  gap: 20px;
  border-bottom: none;
}

.section_head {
  text-align: center;
}

.vertical-line {
  border-left: 2px solid var(--color-secondary);
  height: 70px;
}

/******** Our Products ********/

.category-bg {
  position: relative;
  height: 580px;
}

.brand-bg-img {
  position: relative;
  top: 120px;
  object-fit: cover;
  z-index: -1 !important;
}

.tabs .nav-tabs .nav-link.active {
  border-radius: 10px !important;
  background: #089175 !important;
  color: rgb(255 255 255) !important;
  padding: 10px 15px;
}

.tabs .nav-tabs .nav-link {
  font-family: "Roboto" !important;
  font-weight: 700 !important;
  color: var(--color-black) !important;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-container img {
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee-container-2 {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-container-2 img {
  animation: marquee-2 10s linear infinite;
}

@keyframes marquee-2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.brand-bg-img img {
  object-fit: cover;
  width: 100%;
}

.my-container.container {
  width: 80%;
  margin: 0px auto;
}

.by-brands .slick-track {
  margin: 60px 0px 0 0;
  height: 500px;
}

.nav .nav-link {
  transition: none;
  background: none;
}

.background-img {
  background-image: url(./images/bg_imgae.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.best_sell_icon img {
  width: 50px;
}

.best_sell_icon p {
  font-size: 18px;
  color: var(--color-secondary);
}

.best_sell_icon {
  text-align: center;
}

.best_sell {
  padding-bottom: 100px;
}

.best_sell_icons {
  display: flex;
  justify-content: space-around;
}

.card {
  border: none;
  transition: all 0.5s;
  margin-top: 25px;
}

.card:hover {
  box-shadow: 1px 1px 10px #878787;
}

img.card_img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin: 15px auto;
}

.star {
  color: gold;
}

.star span {
  color: var(--color-secondary);
}

/* .card_footer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
} */

.card_price {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.discount_price {
  font-size: 20px;
  color: #000000;
  margin: 0 !important;
  font-family: "Roboto";
}
button.card_btn2 {
  padding: 12px 44px;
  color: black;
  background: white;
  border: 1px solid white;
  font-size: 12px;
  font-family: "Poppins";
}

button.card_btn2:hover {
  color: var(--color-white);
  background: transparent;
  border: 1px solid white;
}

.cards {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 10px !important;
  background: #ffffff !important;
  color: rgb(0, 0, 0) !important;
  padding: 10px 15px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

ul.mb-3.nav.nav-tabs {
  display: flex;
  justify-content: space-around !important;
  border-bottom: 0px;
}

.products_btn {
  margin-top: 60px;
  text-align: center;
}

/******** Our Products ********/
/******** HeroSlider ********/

/******** HeroSlider ********/
/******** Our Brands ********/
.slider_imge img {
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.slick-prev:before {
  font-size: 28px !important;
  color: var(--color-secondary) !important;
}

.slick-next:before {
  font-size: 28px !important;
  color: var(--color-secondary) !important;
}

/******** Our Brands ********/
/******** Our Best Sell ********/
.best_sell .slick-slide {
  padding: 0 10px 0;
}

.slick-prev:before {
  content: url("./images/right-arrow.png") !important;
}

.slick-next:before {
  content: url("./images/left-arrow.png") !important;
}

.best_sell ul.slick-dots {
  bottom: -30px !important;
  left: 0px !important;
}

/******** Our Best Sell ********/

/******** Section Header Page Css ********/

.header__container-bg img {
  width: 100%;
}

.header__container {
  display: grid;
  height: 100%;
  place-items: center;
  position: relative;
  width: 100%;
}

.header__content h1 {
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 0.7rem;
}

.header {
  border-bottom: 2px solid var(--color-gray-400);
  height: 21rem;
  overflow: hidden;
}

.header__container-bg {
  position: absolute;
  width: 100%;
}

.header__content {
  color: #fff;
  color: var(--color-white);
  margin: 0 auto;
  position: relative;
  text-align: center;
}

.header__top-bg img {
  width: 60%;
  box-shadow: -10px 10px 20px rgba(204, 178, 153, 0.7);
}

.header__top-bg {
  position: absolute;
  top: 148px;
  left: 203px;
}

.header__content1 {
  color: #000;
  position: absolute;
  text-align: justify;
  background: #03c8a0;
  width: 53% !important;
  margin: 0 0 0 380px !important;
  top: 333px;
  left: 165px;
  padding: 30px 80px !important;
  border-radius: 5px;
}

.header__content1 h4 {
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.header__content1 p {
  margin-bottom: 0 !important;
}

.arrow-direction {
  text-align: center;
  position: relative;
  top: -50px;
  left: 82.3%;
  font-size: 36px;
  background-color: #089175;
  display: block;
  width: 5%;
  height: 59px;
  padding: 0px 0px 0px 0px;
}

.arrow-direction svg {
  color: white;
  transform: rotate(40deg);
}

/******** Section Header Page Css ********/
/******** About Us ********/

.section_head_two h2 {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section_head_two_p {
  text-align: justify;
}

.about_img {
  width: 90%;
  height: 100%;
  position: relative;
}

.about_img img {
  object-fit: cover;
  width: 100%;
  position: relative;
}

.padding {
  padding: 100px 0px;
}

.about_mission {
  position: absolute;
  background: var(--background-gradient2);
  width: 200px;
  color: var(--color-white);
  padding: 20px;
  bottom: -30px;
  left: 40px;
}

.about_img:before {
  content: "";
  width: 200px;
  height: 270px;
  position: absolute;
  display: block;
  background: #e5e5e5;
  top: -40px;
  right: -42px;
}

.about_mission h4 {
  color: var(--color-white);
  font-weight: 600;
}

.about_mission p {
  margin: 0px;
  font-size: 12px;
}

/******** About Us ********/
/******** Shop page ********/

.pb-5.container {
  padding-top: 5rem;
}

.shop-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.category {
  margin-top: 27px;
}

.category_btn {
  outline: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #adadad;
}

.category_btn p {
  margin: 0 !important;
}

.category_btn input[type="checkbox"] {
  margin: 0 10px 0 0px;
}

h5.categories {
  background: var(--color-secondary);
  padding: 15px;
  margin: 0px;
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
}

.category-name-color button.accordion-button.collapsed {
  /* font-size: 18px; */
  background: var(--background-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .category-name-color button.accordion-button::after {
  background-image: var(--background-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.shop_category .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 12px;
}

.shop_category .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

h5.price-range {
  background: var(--color-secondary);
  padding: 15px;
  margin: 0px;
  color: var(--color-white);
  font-size: 22px;
  font-weight: 600;
}

ul.price__range {
  margin: 0px;
  padding: 0px;
  background: var(--color-white);
  color: #000;
}

ul.price__range li {
  padding: 15px 20px;
  border-bottom: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  gap: 40px;
}

.price-span {
  display: flex;
  gap: 20px;
  font-size: 13px;
}

.sort-by {
  display: flex;
  justify-content: space-between;
  margin-top: 27px;
  margin-left: 17px;
  align-items: center;
}

.sort-by-form-group {
  display: flex;
  gap: 20px;
}

form.sort-by-form select#rating-filter {
  width: 300px;
  padding: 13px 15px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

form.sort-by-form-2 select {
  width: 150px;
  padding: 13px 15px;
  border: 1px solid #dee2e6;
  color: var(--color-secondary);
}

.page-show-span {
  display: flex;
  gap: 12px;
  font-size: 14px;
}

/******** Shop Page Css********/
/******** Testimonial Page Css ********/
.testimonial-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial-cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding: 90px 0px;
}

.testimonial_card.card {
  border-radius: 0px;
  border: transparent;
  margin: 0px;
}

.testimonial_card {
  width: 18rem;
  text-align: center;
}

img.testimonial_card_img {
  border-radius: 100px;
  width: 60px;
  height: 60px;
  object-fit: cover;
  text-align: center;
  margin: 0px auto;
  margin-top: 25px;
}

.testimonial_card_text {
  font-size: 12px;
  margin: 25px 0px;
}

h5.testimonial_card_name {
  font-size: 24px;
  font-weight: 600;
}

/******** Testimonial Page Css ********/
/******** Blogs Page Css ********/

.blog-post {
  display: flex;
  justify-content: center;
}

.blog-posts {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  gap: 25px;
}

.blog_card {
  margin: 0px;
  width: 22rem;
  overflow: hidden;
  text-align: center;
  border: 0px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

.blog_card:hover {
  box-shadow: none;
}

.blog_text {
  align-items: center;
  background: #fff;
  width: 280px;
  margin: -60px auto 14px;
}

.blog_text:hover {
  box-shadow: 0px 0px 12px #878787;
  transition: all 0.3s ease-in-out;
}

.date_admin p {
  font-size: 12px;
}

.date_admin p.bolg_title {
  font-size: 14px;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 0 15px 0;
}

p.read-more {
  color: black;
  font-size: 12px;
  margin: 0 !important;
}

p.read-more::before {
  content: "";
  background-color: #089175;
  width: 200px;
  height: 1px;
  display: block;
  margin: 0px auto;
}

.blog_text_truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3em;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.all_card {
  width: 18rem;
}

.all_card a {
  text-align: center;
}

img.blog_card_img {
  height: 200px;
  object-fit: cover;
  border-radius: 0px;
}

.date_admin a p {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--color-secondary);
  justify-content: center;
}

.date_admin a p span svg {
  color: var(--color-primary);
}

/******** Blogs Page Css ********/

/******** Single Blogs Page Css ********/

.single-blog-container {
  padding: 120px 0;
}

.right_side .img-fluid {
  width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: -20px 20px 17px 7px rgba(0, 0, 0, 0.3);
}

.single_blog p {
  text-align: justify !important;
}

/******** Single Blogs Page Css ********/
/******** Faqs Page Css ********/
.faq-bg {
  background-image: url(./images/shop-bg.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.faq_section {
  padding: 90px 0px;
}

.faq-accordion {
  width: 85%;
  margin: 0px auto;
}

.faq_section_heading {
  padding: 60px 0px;
  font-size: 48px;
  font-weight: 700;
  color: var(--color-gray-400);
  text-align: center;
}

.faq_section_heading span {
  color: var(--color-primary);
}

.faq-accordion button.accordion-button.collapsed {
  background: var(--color-black) !important;
  color: var(--color-white);
}

.faq-accordion button.accordion-button::after {
  color: var(--color-white);
}

.faq-accordion-body.accordion-body {
  font-size: 14px;
  background: #000;
  color: var(--color-secondary);
  border-left: 3px solid var(--color-primary);
}

.faq-accordion-header button.accordion-button {
  background: var(--background-gradient2) !important;
  color: #fff;
}

.faq-Accordion-item.accordion-item {
  margin-bottom: 30px;
}

h2.faq-accordion-header.accordion-header {
  border-left: 3px solid var(--color-primary);
}

.faq-accordion .faq-accordion-header .accordion-button::after {
  background-image: url(./images/arrow-img-removebg-preview.png) !important;
}

.faq-accordion .faq-accordion-header .accordion-button:not(.collapsed)::after {
  filter: brightness(3);
}

/******** Faqs Page Css ********/
/******** Start Single Product Page Css ********/

.product-detail {
  padding: 90px 0px;
  width: 90%;
  margin: 0px auto;
}

.product-detail-slider {
  width: 80% !important;
}

.product-detail-slider img {
  width: 100%;
}

.product-detail-slider-sec {
  width: 40% !important;
}

.product-detail-slider-sec img {
  width: 100%;
}

.product-detail .slick-slide {
  text-align: center;
}

.slider-container img {
  width: 100%;
}

.product_detail_info_Heading {
  display: flex;
  justify-content: space-between;
}

.product_detail_info_Heading h3 {
  font-weight: 700;
}

.product_detail_info_Heading h2 {
  font-weight: 700;
  background: #089175;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p.product_detail_info_des {
  font-size: 14px;
  color: var(--color-secondary);
}

.product_detail_info_quantity {
  display: flex;
  gap: 50px;
  align-items: flex-start;
}

.product_detail_info_quantity p {
  font-size: 18px;
  font-weight: 600;
}

.quantity_btns button {
  background: transparent;
  width: 18px;
  height: 18px;
  border-radius: 50px;
  line-height: 1px;
}

.quantity_btns button:hover {
  background: #089175;
  color: #fff;
  padding: 0px 0px 4px;
}

.quantity_btns input {
  width: 100px;
  background: #fff;
  border: 1px solid #c0c0c0;
  text-align: center;
}

.quantity_btns {
  display: flex;
  gap: 14px;
  align-items: center;
}

.product_detail_info_delivery a {
  color: var(--color-secondary);
}

.product_detail_info_delivery a span svg {
  color: var(--color-black);
  transform: rotateY(180deg);
  font-size: 20px;
}

p.product_detail_info_delivery_link {
  display: flex;
  gap: 20px;
  font-size: 14px;
}

.product_detail_info_des_tab.nav.nav-pills {
  gap: 50px;
  padding: 60px 0px;
}

.main_btn2 .nav-link {
  padding: 0px;
}

.nav-pills .main_btn2 .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--color-white);
  border: 1px solid transparent;
  background: #089175;
  border-image-slice: 1;
  padding: 8px 25px;
  transition: all 0.5s;
}

.nav-pills .main_btn2 .nav-link {
  color: var(--color-secondary);
  background: #089175;
  padding: 8px 25px;
  border-radius: 0px;
  font-weight: 600;
}
/******** Review Page Css ********/
.review_main h3 {
  font-weight: 600;
}

.single_product_review .product_rating {
  display: flex;
  flex-direction: column;
}
span._star {
  color: #089175;
  display: inline-block;
}
.single_product_review .product_rating label {
  font-size: 18px;
  color: var(--color-black);
  margin: 8px 0px;
}

.product_rating svg {
  width: 25px;
}

.single_product_review .product_rating textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #ddd;
  resize: none;
  border-radius: 0;
  color: #333;
  padding: 10px 15px;
}

.review_top {
  padding: 0 0 15px;
}

.review_top svg {
  width: 25px;
}

.review_top p {
  margin: 0 !important;
}

.review_top h4 {
  font-weight: 600;
  text-transform: uppercase;
}

/******** End Single Product Page Css ********/
/******** Page Not Found Page Css ********/
.page-not-found {
  padding: 5rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-found h2 {
  font-size: 10rem;
  font-weight: 700;
  background: var(--background-gradient1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-not-found h3 {
  font-size: 5rem;
  font-weight: 700;
  color: #089175;
}

.page-not-found p {
  width: 75%;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--color-black);
  margin: 2rem 0;
}

/******** Page Not Found Page Css ********/
/* custom_btn Button */
.custom_btn {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px 25px 15px;
  min-width: 150px;
}

.custom_btn a {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 10px;
  transition: 0.3s;
}

.custom_btn::after {
  position: absolute;
  content: "";
}

.custom_btn::before {
  position: absolute;
  content: "";
  border-radius: 6px;
  background: #089175;
  transition: 0.3s ease-out;
}

.custom_btn a {
  border-radius: 6px;
  color: var(--color-white);
  border: 1px solid #089175;
}

.custom_btn a:hover {
  color: #089175;
  transition: 0.2s 0.1s;
}

.custom_btn::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.custom_btn:hover::before {
  height: 0%;
}

/* custom_btn Button */
/* custom_btn_card Button */
.custom_btn_card {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom_btn_card a {
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 10px;
}

.custom_btn_card::after {
  position: absolute;
  content: "";
}

.custom_btn_card::before {
  position: absolute;
  content: "";
  background: #089175;
  transition: 0.3s ease-out;
  border-radius: 10px;
}

.custom_btn_card a {
  color: var(--color-white);
  border: 1px solid #089175;
  transition: 0.2s 0.1s;
  border-radius: 10px;
}

.custom_btn_card a:hover {
  color: #089175;
  transition: 0.2s 0.1s;
}

.custom_btn_card::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.custom_btn_card:hover::before {
  height: 0%;
}
/* custom_btn_card Button */
/* Loader css */
.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 3px solid var(--color-primary);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader2 {
  border: 3px solid var(--color-primary2);
  width: 220px;
  height: 220px;
  position: relative;
  top: -210px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader3 {
  border: 3px solid var(--color-primary);
  width: 240px;
  height: 240px;
  position: relative;
  top: -441px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

#loader4 {
  border: 3px solid var(--color-primary2);
  width: 260px;
  height: 260px;
  position: relative;
  top: -692px;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: rotate2 2s cubic-bezier(0.26, 1.36, 0.74, -0.29) infinite;
}

@keyframes rotate {
  0% {
    transform: rotateZ(-360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateZ(360deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
#text {
  color: rgb(14, 13, 13);
  font-family: Arial;
  font-size: 20px;
  position: relative;
  top: -840px;
}
/* Loader css */

/******** BOLT CORP CSS START ********/
/******* Start custom_btn2 Button ********/
button.custom_btn2 {
  font-size: 12px;
  padding: 12px 44px;
  background: var(--color-white);
  border: 1px solid var(--color-white);
  color: var(--color-black);
  font-family: Poppins;
}

button.custom_btn2:hover a {
  color: var(--color-black);
}

button.custom_btn2:hover {
  border: 1px solid var(--color-white);
  background: transparent;
}
/******* END custom_btn2 Button ********/

/***************** start Hedaer style ****************/
a.logo_name.navbar-brand img {
  width: 100%;
}

nav.bg-body-tertiary.nav_bg.navbar.navbar-expand-lg.navbar-light {
  background: #191b1d !important;
  position: relative;
  padding: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(255, 255, 255, 0.9915703097645309) 21%,
      rgba(255, 255, 255, 0) 53%,
      rgba(255, 255, 255, 1) 79%
    )
    1;
}

button.Offcanvas-btn svg {
  color: #fff;
  font-size: 22px;
}

.nav_links {
  justify-content: space-between;
}

.me_auto {
  margin-left: auto;
  gap: 25px;
}

.nav_bar_a a {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  text-decoration: none;
  font-family: Unbounded;
  color: var(--color-white);
  font-weight: 300;
  font-size: 14.5px;
  justify-content: center;
}

.nav_bar_a a::before {
  position: absolute;
  content: "";
  background: #089175;
  width: 0%;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.nav_bar_a a:hover:before {
  color: var(--color-white);
  width: 100%;
}

nav.nav_bar.navbar.navbar-expand-lg.navbar-light {
  padding: 0px;
}

a.nav_dropdown.dropdown-item {
  padding: 10px;
}

a.nav_link.active::before {
  position: absolute;
  content: "";
  background: #089175;
  width: 100%;
  height: 3px;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

i.header-shop-icon svg {
  color: #fff;
  font-size: 20px;
}

.input-group-new {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 999 !important;
}

.input-group-new button {
  padding: 30px 14px;
  font-size: 28px;
  background: #089175;
  color: var(--color-white);
}

a#basic-nav-dropdown {
  color: var(--color-white);
}

.search-modal .modal-content {
  background: rgba(33, 37, 41, 0.8) !important;
}
.search-modal button.btn-close {
  filter: invert(100%);
}

.search-modal form {
  margin: auto auto;
}

input.search-input {
  color: #7b7b7b;
  text-transform: capitalize;
  outline: none;
  border: 0px;
  border-bottom: 1px solid #7b7b7b;
  width: 850px;
  padding: 4px 18px;
  background: transparent !important;
  font-size: 35px;
}
.search-modal form .search-btn {
  display: none;
}

.dropdown-menu.show {
  display: block;
  background-color: #191b1d;
}

.client-img img {
  border-radius: 25px;
  height: 50px !important;
  width: 100% !important;
}

/*****************end  Header style ****************/
/******** End footer ********/
.footer_section {
  padding: 22px 0px;
  background: #089175;
}

.my-container {
  width: 80%;
  margin: 0px auto;
}

.news h2 {
  font-weight: 700;
  color: var(--color-white);
  font-family: Roboto;
  margin: 0px;
  font-size: 35px;
}

.footer_logo_section h5 {
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 500;
}

.footer_btn {
  justify-content: flex-end !important;
}

.footer_section2 {
  background-color: #020001;
  padding: 32px 0px;
}

.footer_logo_section {
  color: var(--color-white);
}

.footer_logo_section p {
  margin-top: 30px;
}

.footer_icon {
  display: flex;
}

.footer_icon ul {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 8px;
}

.footer_icon ul li img {
  width: 30px;
  transform: scale(1);
  object-fit: cover;
  transition: all 0.4s ease-in-out;
}

.footer_icon ul li img:hover {
  width: 30px;
  transform: scale(1.25);
}

.footer-links h5 {
  color: var(--color-white);
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: 500;
  padding: 0px 0px 20px 0px;
}

.footer-links ul li a {
  color: var(--color-white);
  font-weight: 300;
  padding: 0px 0px 12px 0px;
  position: relative;
  display: inline-block;
  font-size: 14px;
}

.footer-links ul li a::before {
  position: absolute;
  content: "";
  background: var(--color-white);
  width: 0%;
  height: 2px;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.6s ease;
}

.footer-links ul li a:hover:before {
  color: var(--color-white);
  width: 100%;
}

.footer_inputs input {
  background: #191b1d;
  border: transparent;
  border-radius: 0px;
  outline: none;
  color: var(--color-white);
}

.footer_inputs input:focus {
  color: var(--color-white);
  background: var(--color-black);
}

.footer_inputs input::placeholder {
  color: var(--color-white);
}

.footer_inputs input::-ms-input-placeholder {
  color: var(--color-white);
}

.footer_section3 {
  padding: 10px 0px;
  background: #089175;
}

.footer_card {
  text-align: right;
}

.footer-links ul {
  padding: 0px;
}

p.footer_section3_p {
  color: var(--color-white);
  margin: 0px;
  text-transform: uppercase;
}

/******** End footer ********/
/******** START HeroSlider ********/
.slider_w {
  margin: 0px auto;
  width: 70%;
}

.hero-slider_img_bg {
  float: left;
}

.hero_img0 {
  width: 100%;
}

.hero_img {
  position: relative;
}

.hero_img2 {
  overflow: hidden;
  position: absolute;
  width: 400px;
  top: 50px;
  right: 170px;
  z-index: 999;
  animation-name: hero_img2;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

@keyframes hero_img2 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.hero_img_text {
  position: absolute;
  top: 235px;
  right: 0px;
  width: 100%;
  transition: all 0.7s;
}

.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-container img {
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee-container-2 {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-container-2 img {
  animation: marquee-2 10s linear infinite;
}

@keyframes marquee-2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

ul.slick-dots {
  bottom: 80px !important;
  right: -370px;
}

.slick-dots li button:before {
  color: var(--color-white) !important;
  opacity: 0.5 !important;
}

li.slick-active {
  background-image: url(./images/Group-40.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: transparent !important;
}

/********End HeroSlider ********/

/******** Start shop by brands Component ********/
.by-brands-main {
  padding-top: 50px;
  position: relative;
}

.by-brands-title {
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 45px;
}

.by-brands-title::after {
  content: "";
  background-color: var(--color-secondary);
  width: 300px;
  height: 1.5px;
  display: block;
  margin-left: -82px;
}

.nav .nav-link {
  transition: none;
  background: none;
}

.by-brands-card {
  border: 0px !important;
  transition: all 0.5s;
  text-align: center;
  margin: 0px 10px;
  width: 300px !important;
}

.by-brands-card:hover {
  box-shadow: 1px 1px 10px #878787;
}

.by-brands-cards .slick-track {
  height: 540px;
  margin: 30px 0px 0px 0px;
}

.by-brands-cards .slick-center .by-brands-card {
  transform: scale(1.1) !important;
  display: block !important;
  border: 1px solid #cccccc !important;
}

.by-brands-cards .slick-center .hidden_btn {
  display: block !important;
  margin: 0px auto;
}

img.card_img {
  width: 215px;
  height: 200px;
  object-fit: contain;
  margin: 15px auto;
}

.star {
  color: gold;
}

.star span {
  color: var(--color-secondary);
}

.by-brands-cards-title {
  font-weight: 700 !important;
  font-family: Roboto !important;
  margin: 15px 0px !important;
  font-size: 18px !important;
}

.card_price {
  font-size: 22px;
  font-weight: 700;
  font-family: Roboto;
  margin: 15px 0px;
}

.hidden_btn {
  display: none;
}

.best_sell_row {
  display: flex;
  justify-content: space-between;
}

.by-brands .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  border-radius: 7px !important;
  background-color: var(--color-secondary) !important;
  color: white !important;
}

.best_sell_icon,
.nav-pills .nav-link {
  font-family: "Roboto" !important;
  font-weight: 700 !important;
  color: white !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

ul.mb-3.nav.nav-tabs {
  display: flex;
  justify-content: space-around !important;
  border-bottom: 0px;
}

.cards.shop_bt_brands {
  width: 1180px;
  margin: 60px -510px 0px;
}

/******** End shop by brands Component ********/
/************ Scroll Down button style ************/
.main_hero_slider {
  position: relative;
}

.bottom-to-btm {
  position: absolute;
  bottom: 150px;
  left: 160px;
  padding: 12px;
  z-index: 20;
  border-radius: 50px;
  background-color: #089175;
  animation-name: bottom-to-btm;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

@keyframes bottom-to-btm {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.bottom-to-text {
  position: absolute;
  left: 164px;
  z-index: 20;
  color: #fff;
  font-family: Unbounded;
  top: 622px;
  margin-bottom: 0 !important;
}

.icon-style2 {
  color: var(--color-black);
  cursor: pointer;
  animation: 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
  font-size: 35px;
}

/************ Scroll Down button style ************/
/******** Start About Us Component ********/
.about-main {
  position: relative;
  padding: 100px 0px;
  width: 100%;
}

.about-img-bg {
  background-image: url(./images/about-img-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.about-img img {
  width: 450px;
  object-fit: cover;
  border-radius: 8px;
}

.shadesx-screens {
  position: absolute;
  background: var(--color-white);
  padding: 6px 16px;
  bottom: 240px;
  left: 190px;
  display: flex;
  gap: 15px;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.shadesx-screens-icon {
  font-size: 32px;
  color: #089175;
  padding-bottom: 6px;
}

.shadesx-screens h5 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins";
  margin: 0;
}

.shadesx-screens h5 span {
  color: #089175;
}

.shadesx-screens p {
  color: #089175;
  margin: 0px;
  font-size: 14px;
}

.shadesx-screens:hover {
  background: #089175;
  color: var(--color-white);
}

.shadesx-screens:hover h5 span {
  color: var(--color-black);
}

.shadesx-screens:hover p {
  color: var(--color-white);
}

.shadesx-screens:hover .shadesx-screens-icon {
  color: var(--color-white);
}

.about-text-line::after {
  content: "";
  background-color: #089175;
  width: 170px;
  height: 1px;
  display: block;
  margin-left: -15px;
}

.about-text h1 {
  font-family: "Roboto";
  font-weight: 800;
  font-size: 50px;
}

.about-sub-text {
  font-family: "Poppins";
  font-size: 12px;
  margin-bottom: 35px;
}

.about-sub-text-2 {
  display: flex;
  gap: 40px;
  margin-bottom: 35px;
}

.about-sub-text-2 p {
  position: relative;
  font-size: 12px;
  font-family: "Poppins";
  margin-left: 30px;
}

.about-sub-text-2 p::before {
  content: "";
  background-color: #089175;
  width: 12px;
  height: 12px;
  border-radius: 15px;
  display: block;
  position: absolute;
  left: -30px;
  top: 12px;
}

.active_outdoor_img {
  position: absolute;
  top: 110px;
  left: 25px;
  width: 10% !important;
}

.active_outdoor_img img {
  width: 85px;
  object-fit: cover;
  animation-name: active_outdoor_img;
  animation-duration: 4s;
  animation-delay: 2s;
  animation-iteration-count: infinite;
}

@keyframes active_outdoor_img {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.15;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.15;
  }
}

/******** End About Us Component ********/
/******** Start Categories Component ********/
.category-bg {
  position: relative;
  height: 580px;
}

.category-bg-img {
  position: absolute;
  top: 0px;
  object-fit: cover;
  z-index: -1 !important;
}

.category-bg-img img {
  object-fit: cover;
  width: 100%;
}

.category-title h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto";
  padding: 50px 0px;
  font-size: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-title h1::after {
  content: "";
  background-color: #089175;
  width: 340px;
  height: 1.5px;
  display: block;
}

.category-img {
  position: relative;
  background: var(--color-black);
  overflow: hidden;
  border-radius: 9px;
}

.category-img-p {
  position: absolute;
  font-size: 32px;
  font-family: "Roboto";
  font-weight: 600;
  color: var(--color-white);
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  text-align: center;
}

.category-img img {
  transform: scale(1);
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
}

.category-img:hover img {
  transform: scale(1.2);
  opacity: 0.5;
}

/******** End Categories Component ********/
/******** Start Collection Component ********/
.collection-main {
  background-image: url(./images/collection.png);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

.collection-body {
  padding-top: 150px;
}

.collection-body-sub-tital {
  font-size: 25px;
  font-family: "Poppins";
  color: #089175;
  margin: 0px;
}

.collection-body-titla {
  margin-bottom: 18px;
  color: var(--color-white);
  font-family: "Poppins";
  font-weight: 700;
  text-transform: uppercase;
}

.collection-body-img img {
  width: 1050px;
}

.slider_imge img {
  text-align: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
}

.slick-prev:before {
  content: url("./images/right-arrow.png") !important;
}

.slick-next:before {
  content: url("./images/left-arrow.png") !important;
}

/******** End Collection Component ********/
/******** Satr Contact us Component ********/

.Contact-main {
  position: relative;
  padding-top: 50px;
  background-image: url(./images/contact-us.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 120px;
  height: 620px;
}

.Contact-main-bg-img {
  position: absolute;
  top: 65px;
  object-fit: cover;
}

.Contact-main-bg-img img {
  object-fit: cover;
  width: 100%;
}

.contact-form {
  z-index: 999 !important;
}
.contact-form h1 {
  color: var(--color-white);
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto";
  padding: 60px 0px;
  font-size: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form h1::after {
  content: "";
  background-color: var(--color-white);
  width: 340px;
  height: 1px;
  display: block;
}

.contact_us_tittle h5 {
  font-size: 50px;
  font-family: "Syne";
  color: rgb(255, 255, 255);
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 100px;
}

form.contact_form input {
  width: 100%;
  padding: 20px 20px;
  border: none;
  margin: 18px 0px;
  outline: none;
}

form.contact_form input::placeholder {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-family: "Syne";
}

form.contact_form textarea {
  width: 100%;
  padding: 20px 20px;
  border: none;
  margin: 10px 0px;
  outline: none;
  resize: none;
  box-shadow: 1px 1px 20px rgb(0 0 0 / 20%);
}

form.contact_form .theme-group {
  display: flex;
  justify-content: center;
}

.contact_btn {
  text-align: center;
}

/******** End Contact us Component ********/
/******** Start Latest Blogs Component ********/
.latest-blogs-main {
  position: relative;
  padding: 30px 0px 40px 0px;
  /* background-image: url(./images/latest-blogs.png); */
  background-repeat: no-repeat;
  background-size: contain;
}

.latest-blogs-img {
  position: absolute;
  top: 0px;
  object-fit: cover;
  z-index: -1 !important;
}

.latest-blogs-img img {
  object-fit: cover;
  width: 100%;
}

.latest-blogs-title h1 {
  padding-bottom: 50px;
  color: var(--color-black);
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto";
  font-size: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-post {
  display: flex;
  justify-content: center;
}

.blog-posts {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 13px;
}

.blog_card {
  margin: 0px;
  width: 18rem;
  overflow: hidden;
  text-align: center;
  border: 0px !important;
  border-radius: 0px !important;
  background-color: transparent !important;
}

.blog_card:hover {
  box-shadow: none;
}

.blog_text {
  align-items: center;
  background: #fff;
  width: 230px;
  margin: -60px auto 14px;
}

.blog_text:hover {
  box-shadow: 0px 0px 12px #878787;
  transition: all 0.3s ease-in-out;
}

.all_card {
  width: 18rem;
}

.all_card a {
  text-align: center;
}

img.blog_card_img {
  height: 200px;
  object-fit: cover;
  border-radius: 0px;
}

.date_admin a p {
  color: #000;
  font-size: 12px;
  margin: 4px;
  font-family: "Poppins";
}

.latest-blogs-title p {
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 700;
}

a.read-more {
  font-size: 12px;
  color: var(--color-black);
}

a.read-more::before {
  content: "";
  background-color: var(--color-secondary);
  width: 200px;
  height: 1px;
  display: block;
  margin: 0px auto;
}

/******** End Latest Blogs Component ********/
/******** Request Quote Section Page Css START********/

.request-main {
  padding: 120px 0;
}

.request-form h1 {
  color: #03c8a0;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Roboto";
  font-size: 33px;
}

.request-border {
  border-bottom: 1px solid rgb(224 224 224);
  display: block;
  width: 28%;
  position: relative;
  right: -22rem;
  margin: 0 0 24px;
}

form.request_form input {
  width: 100%;
  padding: 20px 20px;
  border: 1px solid rgb(224 224 224);
  margin: 18px 0px;
  outline: none;
}

form.request_form input::placeholder {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-family: "Syne";
}
form.request_form textarea::placeholder {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-family: "Syne";
}

form.request_form textarea {
  width: 100%;
  padding: 20px 20px;
  border: 1px solid rgb(224 224 224);
  margin: 10px 0px;
}

form.request_form .theme-group {
  display: flex;
  justify-content: center;
}

.aling.container {
  padding: 70px 0 0px;
}

ul#uncontrolled-tab-example {
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
  border: none;
  padding: 0 0 50px;
}

.aling.container .nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: rgb(200 196 196);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-weight: 700;
}

span.input-group-icon {
  position: relative;
  top: -65px;
  right: -465px;
}

span.input-group-icon1 {
  position: relative;
  top: -178px;
  right: -989px;
}

span.input-group-icon svg {
  color: #03c8a0;
}

span.input-group-icon1 svg {
  color: #03c8a0;
}

.product_slide_marquee marquee {
  background: black;
  color: white;
  display: flex;
  gap: 60px;
  padding: 13px 0;
}

.product_slide_marquee marquee span {
  padding: 0 40px;
  font-size: 27px;
  font-family: "Unbounded";
  letter-spacing: 4px;
}

.tab-content h1 {
  color: #03c8a0;
  text-transform: uppercase;
  font-weight: 800;
  font-family: "Roboto";
  font-size: 33px;
}

/******** Request Quote Section Page Css END********/
/******** Side Bar main-section Page Css ********/
.sidebar {
  background: #ffffff !important;
  color: black !important;
  min-height: 80vh !important;
  padding: 15px 0px;
}

.main-section {
  padding: 150px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 100%;
  min-height: 100vh;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

.sidebar {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 16.875rem;
  max-width: 16.875rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: block;
  z-index: 999;
  left: 0;
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
  border: 1px solid #c7c7c3;
}

.main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0;
  flex-basis: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 0;
  min-width: 0;
  max-width: 100%;
  min-height: 1px;
}

.stock ul {
  border-bottom: 1px solid #d6d6da;
  margin: 0 0 30px 0;
}

.sidebar ul li {
  margin: 0px 0 12px 0px;
}

input.slim-range-slider {
  width: 139%;
  height: 1px;
}

span.price-label {
  padding: 0px 10px 0 0;
  font-size: 12px;
  font-weight: 400;
}

.sidebar span {
  padding: 0px 0px 0px 10px;
  font-weight: 700;
}

.sidebar label {
  display: flex;
  color: #adadad;
}

.sidebar label input[type="checkbox"] {
  margin: 0px 10px 0px 0px;
}

.stock-count {
  color: #adadad;
  padding: 0px 0px 0px 50px;
  margin-bottom: 0 !important;
}

.price ul {
  margin: 0px 0 30px 0;
}

.price ul li:first-child label input {
  border: 1px solid;
  width: 83%;
  height: 35px;
  text-align: center;
}

.price ul li:nth-child(2) label input {
  border: 1px solid;
  width: 78%;
  height: 1px;
  text-align: center;
}
.price label {
  padding: 0 !important;
}

.price ul li:first-child {
  display: flex;
}

.price-labels span {
  color: #adadad;
  padding: 0px 13px 0 0;
  font-size: 13px;
  font-weight: 400;
}

.category-1 ul {
  margin: 0 0 30px 0;
}

.category-1 ul li {
  margin: 0px 0 12px 0px;
}

.category-1 label {
  padding: 0px 9px 0 0px;
  color: #adadad;
}

.category-1 .stock-count {
  color: #adadad;
  padding: 0px 0px 0px 14px;
  margin-bottom: 0 !important;
}

.category-2 ul {
  margin: 0 0 30px 0;
}

.category-2 ul li {
  margin: 0px 0 12px 0px;
}

.category-3 ul {
  margin: 0 !important;
}

.main-content2 {
  width: 1280px;
}

/******** Side Bar Main Section Page Css ********/

/******** Product Main Section Page Css ********/

.shop_detail_main {
  padding: 50px 20px 50px 20px;
}

.product-card {
  padding: 0 0 20px 0px;
}

.brandclasss {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 80px;
}

.shop_detail_main h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}

p.heading {
  text-align: center;
  margin: 0 auto;
  width: 50%;
  padding-bottom: 50px;
}

.banner-title {
  position: relative;
  top: -335px;
  left: 100px;
}

.banner-title h1 {
  color: white;
  margin-bottom: 0 !important;
  font-size: 60px;
}

.banner-img img {
  width: 100%;
}

.products {
  padding: 0 0 30px 0px;
  width: 100%;
}

.no-product-found p {
  font-size: 40px;
  font-weight: 700;
  color: black;
  padding: 0 !important;
  text-align: center;
}

.no-product-found {
  padding: 100px 0px;
}

.product-card img {
  height: 310px;
  object-fit: contain;
  width: 100%;
}

.product-card a:hover {
  transform: translate(0px, -10px);
  background: transparent;
  border: 1px solid #089175;
  color: #089175;
}

.product-title a {
  color: black !important;
  background-color: white !important;
  text-decoration: underline !important;
  text-align: center;
  display: block;
}

.product_slide img {
  width: 100%;
}

.product_slide {
  border-bottom: 1px #dadada;
  padding: 0 0 73px 0px;
  height: 300px;
  position: relative;
}

.products h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  margin: 0 !important;
  padding: 26px 0 40px;
}

.product-title span {
  display: block;
  padding: 0 0 10px 0;
}

.product-title svg {
  color: #eadc5b;
}

.poduct-price {
  display: flex;
  padding: 0 0 20px 0px;
}

.poduct-price p {
  text-decoration: line-through;
  padding: 0px 0px 0px 30px;
  margin: 0 !important;
}

.poduct-price span {
  font-weight: 700;
  padding: 0 !important;
}

.product-card a {
  background-color: #089175;
  padding: 10px 10px;
  color: white;
  border: 1px solid #089175;
  transition: all 0.3s ease-in-out;
  display: block;
  width: fit-content;
  margin: 10px 0 0 0;
}

.percent {
  position: relative;
  top: -472px;
  left: 203px;
}

.percent p {
  background-color: #089175;
  border-radius: 255px;
  width: 45px;
  height: 45px;
  padding: 8px 0px 0px 7px;
  font-weight: 700;
  font-size: 17px;
  color: white;
}

.percent1 {
  position: relative;
  top: -472px;
  left: 226px;
}

.percent1 p {
  background-color: #089175;
  border-radius: 255px;
  width: 45px;
  height: 45px;
  padding: 8px 0px 0px 7px;
  font-weight: 700;
  font-size: 17px;
  color: white;
  margin: 0 !important;
}

.percent2 {
  position: relative;
  top: -501px;
  left: 180px;
}

.percent2 p {
  background-color: #089175;
  border-radius: 255px;
  width: 45px;
  height: 45px;
  padding: 8px 0px 0px 7px;
  font-weight: 700;
  font-size: 17px;
  color: white;
}

.Image a {
  background: white;
  padding: 0 !important;
  border: 0 !important;
  margin: 0 !important;
}

/******** product Main Section Page Css ********/

/******** product-slide-2 Section Page Css ********/

.product-slide-text {
  position: absolute;
  top: 29px;
  left: 14rem;
  width: 330px;
}

.product-slide-text span {
  color: white;
  font-weight: 700;
  font-size: 20px;
}

.product-slide-text h2 {
  color: white;
  font-weight: 900;
  font-size: 35px;
}

.product-slide-text p {
  color: white;
  font-weight: 500;
}

.product-slide-text a {
  color: white;
}

.products-last {
  padding: 0 0 70px 0;
}

/******** product-slide-2 Section Page Css END ********/

/******** pagination  Section Page Css ********/

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
  justify-content: center;
  background-color: transparent;
}

.pagination .page-item {
  margin: 0 5px;
}

.pagination .page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-decoration: none;
  color: black;
  background-color: transparent;
  border: none;
}

.pagination .active .page-link {
  background-color: transparent;
  color: rgb(0, 0, 0);
  border-color: rgb(255, 255, 255);
}

.pagination .page-link:hover {
  background-color: transparent;
  color: rgb(0, 0, 0);
}

.pagination .page-link svg {
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
}

.pagination .page-item .page-link:focus,
.pagination .page-item .page-link:active {
  outline: none !important; /* Ensure the focus outline is removed */
  box-shadow: none !important; /* Remove any box-shadow on focus */
}

.disabled > .page-link,
.page-link.disabled {
  background: none !important;
}

.card-body {
  padding: 20px 5px 5px !important;
  text-align: center !important;
}

.card-body .long_description {
  height: 53px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

/******** pagination  Section Page Css END********/

/******** BOLD CORP CSS END ********/
